.course-wrapper{
    .head{
        ul{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px 43px;
            box-sizing: border-box;
            background-color: #009BE0;
            li{
                list-style: none;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 27px;
                text-align: left;
                color: #FFFFFF;
            }
            li:nth-child(1){
                width: 5%;
            }
            li:nth-child(2){
                width: 22.5%;
            }
            li:nth-child(3){
                width: 22.5%;
            }
            li:nth-child(4){
                width: 20%;
            }
            li:nth-child(5){
                width: 10%;
            }
        }
    }
}