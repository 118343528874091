*{
  margin: 0;
  padding: 0;
  list-style: none;
}

.admin-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .sidebar{
    min-height: 100vh;
    width: 25%;
    padding: 77px 44px;
    box-sizing: border-box;
    background: #1095E7;
    ul{
      li{
        list-style: none;
        width: 100%;
        box-sizing: border-box;
        a{
          border-radius: 9px;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 22px;
          line-height: 27px;
          color: #fff;
          text-decoration: none;
          padding: 24px 0 24px 41px;
          display: block;
          &.active{
            background-color: #fff;
            color: #242424;
          }
        }
      }
    }
  }
  .main-page{
    width: 75%;
    padding: 77px 53px;
    height: 100vh;
    overflow: scroll;
    box-sizing: border-box;
  }
}