.list-wrapper{
    ul{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #000;
        padding: 24px 24px 24px 44px;
        li{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 27px;
            text-align: left;
            color: #242424;
        }
        li:nth-child(1){
            width: 5%;
        }
        li:nth-child(2){
            width: 22.5%;
        }
        li:nth-child(3){
            width: 22.5%;
        }
        li:nth-child(4){
            width: 20%;
        }
        li:nth-child(5){
            width: 10%;
            display: flex;
            align-items: center;
            gap: 9px;
            button{
                min-width: 25px;
                height: 25px;
                border: none;
                background: #D6D6D6;
                border-radius: 8px;
                cursor: pointer;
                &.colorone{
                    background-color: #DCE000;
                }
                &.colortwo{
                    background-color: #009BE0;
                }
                &.colorthree{
                    background-color: #E00000;
                }
            }
        }
    }
}